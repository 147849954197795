<template>
  <div class="agreement-page" :class="{'app-agreement' : isMobile}">
    <div class="title">隐私政策</div>
    <div class="content">
      <p>生效日期：2023年9月13日</p>
      <p>更新日期：2023年9月6日</p>
      <p>欢迎您体验通义千问服务！</p>
      <p class="bold">【审慎阅读】</p>
      <p>
        您在同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款的内容，特别是免除或者限制责任的条款、法律适用和争议解决条款，这些条款将以粗体或粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可以通过本协议披露的联系方式与我们沟通。
      </p>
      <p class="bold">【签约动作】</p>
      <p>
        当您阅读并点击同意本协议或以其他方式选择接受本协议后，即表示您已充分阅读、理解并接受本协议的全部内容，并与我们达成一致。本协议自您通过网络页面点击确认或以其他方式选择接受本协议之日起成立。阅读本协议的过程中，如果您不同意本协议或
      </p>
      <p class="bold">【审慎阅读】</p>
      <p>
        您在同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款的内容，特别是免除或者限制责任的条款、法律适用和争议解决条款，这些条款将以粗体或粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可以通过本协议披露的联系方式与我们沟通。
      </p>
      <p class="bold">【签约动作】</p>
      <p>
        当您阅读并点击同意本协议或以其他方式选择接受本协议后，即表示您已充分阅读、理解并接受本协议的全部内容，并与我们达成一致。本协议自您通过网络页面点击确认或以其他方式选择接受本协议之日起成立。阅读本协议的过程中，如果您不同意本协议或
      </p>
    </div>
  </div>
</template>

<script>
import {isMobile} from "@/utils";

export default {
  name: "privacyPolicy",
  data() {
    return {
      isMobile: isMobile()
    }
  }
}
</script>

<style lang="scss" scoped>
.agreement-page {
  color: #333;
  max-width: 840px;
  margin: 0 auto;
  padding-bottom: 60px;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  .title {
    text-align: center;
    font-size: 24px;
    font-family: PingFangSC;
    font-weight: 600;
    color: rgba(0,0,0,0.85);
    line-height: 60px;
  }
  .content {
    padding: 26px 36px;
    font-size: 16px;
    font-family: PingFangSC;
    color: rgba(0,0,0,0.85);
    line-height: 24px;
    p {
      margin-bottom: 20px;
    }
    .bold {
      font-weight: bold;
    }
  }
}
.app-agreement {
  max-width: 22.4rem;
  .content {
    padding: 0;
  }
}
</style>
